@import "../colors";
@import "../mixins";

.header-title {
  padding: 20px 0 30px;
  color: #FFF;

  @include mq(959px) {
    padding: 30px 0;
  }

  @include mq(719px) {
    padding: 20px 0;
  }

  &-wrap {
    background: $secondary;
  }

  &__title {
    font-size: 40px;
    line-height: normal;

    @include mq(959px) {
      font-size: 24px;
    }

    @include mq(719px) {
      font-size: 16px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 28px;
    margin-top: 15px;

    @include mq(959px) {
      font-size: 18px;
      margin-top: 10px;
    }

    @include mq(719px) {
      font-size: 14px;
      margin-top: 15px;
      line-height: 20px;
    }
  }
}