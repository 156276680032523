@import "../mixins";

.brands {
  display: flex;
  margin: -15px;

  @include mq(1199px) {
    margin: -10px;
  }

  @include mq(959px) {
    @include mq(720px, min) {
      flex-wrap: wrap;
      margin: -8px;
    }
  }

  @include mq(719px) {
    margin: -5px;
    padding-left: 7px;
    padding-right: 7px;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: fit-content;
  }

  &-wrap {
    background: #FBF6F1;
    padding: 30px 0;

    @include mq(959px) {
      padding: 20px 0;
    }

    @include mq(719px) {
      padding: 15px 0;
    }

    > .container {
      @include mq(719px) {
        padding: 0;
        overflow: auto;
        height: 60px;
        margin-bottom: -10px;
      }
    }
  }

  &__item {
    flex: 1;
    margin: 15px;
    background: #FFF;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.051);
    border-radius: 5px;

    @include mq(1199px) {
      margin: 10px;
      display: flex;
      align-items: center;
      height: 65px;
    }

    @include mq(959px) {
      flex: unset;
      margin: 8px;
      width: calc(100% / 3 - 16px);
    }

    @include mq(719px) {
      width: 90px;
      height: 50px;
      margin: 5px;
    }

    img {
      max-width: 100%;
    }
  }
}

_:-ms-fullscreen, :root .brands__item img {
  width: 100%;
}