.projects-slider {
  position: relative;
  margin-top: 50px;
  margin-left: -15px;
  margin-right: -15px;

  @include mq(959px) {
    margin-top: 35px;
  }

  @include mq(719px) {
    margin-top: 17px;
  }

  &-arrow {
    @include mq(719px) {
      display: none !important;
    }

    &--prev {
      left: -15px;
    }

    &--next {
      right: -15px;
    }
  }

  &-dots {
    position: static;
    margin-top: 20px;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    margin: 0 15px;
    height: inherit !important;

    > div {
      height: 100%;
    }
  }

  &-item {
    height: 100%;
    background: #E2F2FC;
    padding: 30px 65px;
    display: flex;
    border-radius: 5px;
    text-decoration: none;
    transition: 1s;

    @include mq(1539px) {
      padding: 30px 45px;
    }

    @include mq(719px) {
      flex-direction: column;
      padding: 20px 25px;
    }

    &:hover {
      background: #b4ddf7;
    }

    &-wrap {
      height: 100%;
    }

    &__img {
      flex: 1;
      margin-right: 15px;

      @include mq(719px) {
        margin: auto;
        flex: unset;
        height: 145px;

        img {
          height: 100%;
        }
      }
    }

    &__info {
      flex: 1;
      margin-left: 15px;

      @include mq(1199px) {
        flex: 1.3;
      }

      @include mq(719px) {
        margin: 20px 0 0;
        flex: unset;
      }
    }

    &__data {
      color: #004058;
      font-weight: bold;
      line-height: 22px;

      @include mq(719px) {
        max-width: unset;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__characteristics {
      font-size: 14px;
      line-height: 20px;
      margin-top: 30px;
      color: #222222;

      @include mq(719px) {
        margin-top: 25px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__time {
      font-weight: bold;
      margin-top: 35px;
      color: #004058;

      @include mq(719px) {
        margin-top: 25px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}