@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if  $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}


@mixin mq($width, $type: max) {
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

@mixin mqh($height, $type: max) {
  @media only screen and (#{$type}-height: $height) {
    @content;
  }
}

@mixin mkRow() {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

@mixin mkCol($cols) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% / 12 * #{$cols} - 30px);
}