@import "../colors";
@import "../mixins";

.pagination {
  padding: 18px 24px;
  background: #FBF6F1;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;

  @include mq(719px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  &__total-pages {
    line-height: 34px;
  }

  &-nav {
    display: flex;
    align-items: center;

    &__arrow {
      padding: 10px;
      cursor: pointer;

      &:first-child {
        transform: rotate(90deg);
      }

      &:last-child {
        transform: rotate(-90deg);
      }

      path {
        transition: .3s;
      }

      &:hover {
        path {
          stroke: $primaryHover;
        }
      }
    }

    &-digits {
      display: flex;

      &__item {
        color: #222222;
        margin: 0 10px 2px;
        display: block;
        transition: .3s;
        cursor: pointer;

        &:not(.pagination-nav-digits__item--current):hover {
          color: $primaryHover;
        }

        &--current {
          opacity: .35;
        }
      }
    }
  }
}