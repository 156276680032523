@import "../mixins";

.doc-slider {
  position: relative;
  margin: 40px -30px 0;

  @include mq(719px) {
    margin-top: 30px;
  }

  &-wrap {
    padding: 0 0 60px;
    position: relative;
    background: #FBF6F1;
    overflow: hidden;

    @include mq(1200px, min) {
      margin-top: 60px;
    }

    @include mq(719px) {
      padding: 10px 0 70px;
    }
  }

  &-arrow {
    top: calc(50% - 50px);

    @include mq(719px) {
      display: none !important;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &-dots {
    bottom: 25px;

    @include mq(720px, min) {
      display: none !important;
    }
  }

  .slick-slide {
    margin: 0 30px;
  }

  &-carousel {
    margin-bottom: -20px;

    &:not(.slick-initialized) {
      display: flex;
      justify-content: space-between;
    }
    
    &-item {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.051);
      display: block;
      margin-bottom: 20px;
      position: relative;

      @include mq(719px) {
        max-width: 210px;
        margin: auto;
      }

      &__img {
        img {
          width: 100%;
        }
      }

      &__icon {
        position: absolute;
        right: 8px;
        bottom: 8px;
        transition: .6s;

        img {
          transition: .6s;
          width: 18px;
        }
      }

      &:hover {
        .doc-slider-carousel-item__icon {
          right: calc(50% - 20px);
          bottom: calc(50% - 20px);

          img {
            width: 40px;
          }
        }
      }
    }
  }
}