@import "../mixins";

h1,h2,h3,h4,p,ul,li {
    margin: 0;
    padding: 0;
}

.main-offer {
    background: #E2F2FC;
    padding-bottom: 45px;

    .container {
        position: relative;
    }

    &--title-wrap {
    max-width: 787px;
        @include mq(1199px, max) {
            width: 540px;
        }
        @include mq(959px, max) {
            width: 376px;
        }
        @include mq(719px, max) {
            width: 100%;
        }

    }

    &__main-title {
    font-size: 40px;
    font-weight: bold;
    padding-top: 57px;
    max-width: 700px;
        @include mq(959px, max) {
            font-size: 24px;
        }
        @include mq(719px, max) {
            padding-top: 17px;
            font-size: 16px;
        }
    }

    &__subtitle {
    font-size: 24px;
    font-weight: bold;
    padding-top: 27px;
        @include mq(959px, max) {
            font-size: 16px;
        }
        @include mq(719px, max) {
            padding-top: 9px;
            font-size: 14px;
        }
    }

    &__img img {
        position: absolute;
        right: 57px;
        top: 45px;
        display: block;
        width: 338px;
        max-width: 100%;
        // display: none;
        @include mq(1539px, max) {
            right: 0px;
            top: 54px;
        }
        @include mq(1199px, max) {
            top: 50%;
            transform: translateY(-70%);
            width: 264px;
        }
        @include mq(959px, max) {
            width: 186px;
        }
        @include mq(719px, max) {
            position: static;
            transform: translateY(0);
            margin: 0 auto;
            margin-top: 27px;
        }
    }

    &--list-advance {
        margin-top: 27px;        

        &__item {
            margin: 0;
            padding: 0;
            margin-bottom: 15px;
            font-size: 14px;
            &::before {
                display: none;
            }
            &:last-child {
                margin-bottom: 45px;
                @include mq(959px, max) {
                    margin-bottom: 30px;
                }
                @include mq(719px, max) {
                    margin-bottom: 25px;
                }
            }
            @include mq(719px, max) {
                font-size: 12px;
            }
        }
    }
    &__line {
        margin-bottom: 40px;
        border: none;
        color: #C7DEF1;
        background-color: #C7DEF1;
        height: 1px;
        @include mq(959px, max) {
            margin-bottom: 30px;
        }
        @include mq(719px, max) {
            margin-bottom: 25px;
        }
    }
    &--info {
        display: flex;
        max-width: 790px;
        &__icon {

        }
        &__text {
            font-size: 14px;
            margin-left: 17px;
            // padding-right: 50px;
            opacity: .6;

            @include mq(959px, max) {
                font-size: 12px;
            }
            @include mq(719px, max) {
                margin-left: 10px;
                font-size: 10px;
            }
        }
    }
    
}