@import "../colors";
@import "../mixins";

.vacancy-detail {
  &__title {
    margin-top: 40px;

    @include mq(719px) {
      margin-top: 25px;
      font-size: 16px;

      br {
        display: none;
      }
    }
  }


  &__subtitle {
    color: rgba(#222, .6);
    font-size: 24px;
    line-height: normal;
    margin-top: 20px;

    @include mq(959px) {
      font-size: 16px;
    }

    @include mq(719px) {
      font-size: 14px;
    }
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @include mq(959px) {
      flex-direction: column;
    }
  }

  &__data {
    width: 689px;

    @include mq(1539px) {
      width: 614px;
    }

    @include mq(1199px) {
      width: 540px;
    }

    @include mq(959px) {
      width: 100%;
    }
  }

  &-slider {
    &-slide__img {
      width: 100%;
      height: 465px;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      @include mq(719px) {
        height: 50vw;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-nav {
      display: flex;
      padding: 20px;
      background: #FBF6F1;
      border-radius: 0 0 5px 5px;
      align-items: center;
      justify-content: space-between;
      margin-top: 2px;

      @include mq(719px) {
        padding: 20px 15px;
      }

      &__left {
        display: flex;
        align-items: center;
      }
    }

    &__title {
      margin-left: 30px;
      font-size: 14px;

      @include mq(719px) {
        font-size: 12px;
        padding-left: 14px;
      }
    }

    &-count {
      span {
        margin: 0 14px;
      }
    }

    &__arrows {
      display: flex;
      margin-left: 15px;
    }

    &__arrow {
      cursor: pointer;

      path {
        transition: .3s;
      }

      &:hover {
        path {
          stroke: $primaryHover;
        }
      }

      & + & {
        margin-left: 60px;

        @include mq(719px) {
          margin-left: 25px;
        }
      }

      &:first-child {
        transform: rotate(90deg);
      }

      &:last-child {
        transform: rotate(-90deg);
      }
    }
  }

  &-img {
    &-description {
      display: flex;
      padding: 20px;
      background: #FBF6F1;
      border-radius: 0 0 5px 5px;
      align-items: center;
      justify-content: space-between;
      margin-top: 2px;

      &__title {
        font-size: 14px;

        @include mq(719px) {
          font-size: 12px;
        }
      }
    }
  }

  &__text {
    p + p {
      margin-top: 0.7em;
    }
    img {
      width:100%;
    }

    h2 {
      font-weight: normal;
      font-size: 24px;
      margin-bottom:20px;
    }

  }
  &__table {
    display: flex;
    margin-bottom:20px;


    &__key {
      display:block;
      width:40%;
      color: rgba(#222, .6);
    }
    &__value {
      display:block;
      width:60%;
    }
  }

  &__pdf {
    display:flex;
    width:40%;
  }

  &-form {
    width: 450px;
    padding-top: 30px;

    @include mq(1539px) {
      width: 338px;
      padding-top: 0;
    }

    @include mq(1199px) {
      margin-left: 20px;
      flex: 1;
    }

    @include mq(959px) {
      margin-top: 30px;
      padding-top: 40px;
      border-top: 1px solid #E2E2E2;
      align-items: center;
      display: flex;
      width: 100%;
      flex: unset;
      margin-left: 0;

      & > div {
        width: 278px;
        margin: auto;
        text-align: center;

        @include mq(719px) {
          width: 100%;
          text-align: left;
        }
      }
    }

    &__title {
      font-size: 24px;
      line-height: 28px;

      @include mq(1199px) {
        br {
          display: none;
        }
      }

      @include mq(959px) {
        font-size: 16px;
        line-height: normal;

        br {
          display: block;
        }
      }
    }

    &__subtitle {
      margin-top: 20px;

      @include mq(959px) {
        font-size: 14px;
        line-height: normal;
      }
    }

    &__inputs {
      margin-top: 35px;
    }

    &__input {
      & + & {
        margin-top: 30px;

        @include mq(959px) {
          margin-top: 20px;
        }

        @include mq(719px) {
          margin-top: 16px;
        }
      }
    }

    &-btn {
      width: 100%;
      height: 76px;
      margin-top: 35px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.051);
      border-radius: 5px;

      @include mq(959px) {
        margin-top: 20px;
      }
    }

    &__info {
      font-size: 14px;
      margin-top: 40px;
    }

    &__policy {
      font-size: 14px;
      margin-top: 20px;

      a {
        color: #222;

        &:not(:hover) {
          text-decoration: underline;
        }
      }
    }
  }
}