@import "../colors";
@import "../mixins";

.checkout {
  display: flex;

  @include mq(959px) {
    flex-direction: column;
  }

  &__form {
    flex: 2;
  }

  &__data {
    flex: 1;
    margin-left: 30px;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    margin: 35px 0 30px;

    @include mq(959px) {
      font-size: 16px;
      line-height: normal;
      margin: 40px 0 20px;
    }

    @include mq(719px) {
      margin: 25px 0 20px;
      font-size: 14px;
    }
  }

  &__input-clear {
    position: absolute;
    right: 27px;
    top: calc(50% - 10px);
    cursor: pointer;
    transition: .1s;
    opacity: 0;
    visibility: hidden;
  }

  &-region {
    width: calc(50% - 30px);

    @include mq(1199px) {
      @include mq(960px, min) {
        width: 100%;
      }
    }

    @include mq(719px) {
      width: 100%;
    }

    &__input {
      width: 100%;
      height: 100%;
      padding: 40px 20px 20px;
      font-size: 16px;
      color: #222;
      border: none;
      background: transparent;

      @include mq(719px) {
        font-size: 14px;
        padding-top: 30px;
      }

      &:focus, &:active {
        + label {
          font-size: 12px;
          top: 10px;

          @include mq(719px) {
            top: 7px;
          }
        }
      }
    }
  }

  &__input-parent {
    background: #e2f2fc;
    z-index: 1;
    transition: .3s;
    position: relative;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    height: 76px;

    @include mq(719px) {
      height: 60px;
    }

    label {
      position: absolute;
      left: 20px;
      top: calc(50% - 10px);
      font-size: 16px;
      color: #222;
      transition: .1s;
      z-index: -1;

      @include mq(719px) {
        font-size: 14px;
      }
    }

    &.got-value {
      background: transparent;
      border-color: #E2F2FC;

      label {
        font-size: 12px;
        top: 10px;
        z-index: -1;

        @include mq(719px) {
          top: 7px;
        }
      }

      .checkout__input-clear {
        opacity: 1;
        visibility: visible;
      }
    }

    &.required {
      &:not(.correct):not(.chosen):before {
        content: "*";
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: .6;
        color: #222;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 9px;
      border: 2px solid #53BF31;
      border-radius: 0 0 3px 0;
      border-style: none solid solid none;
      transform: rotate(45deg);
      top: 8px;
      right: 8px;
      transition: .3s;
      opacity: 0;
    }

    &.correct, &.chosen {
      &:after {
        opacity: 1;
      }
    }

    &.required.error {
      border-color: red;
    }
  }
  
  &-radio {
    background: transparent;
    position: relative;

    & + & {
      margin-top: 4px;
    }

    &-container {
      background: #E2F2FC;
      padding: 4px;
      border-radius: 5px;

      &-point {
        width: calc(50% - 30px);

        @include mq(1199px) {
          width: 100%;
        }
      }
    }

    &__inner {
      background: transparent;
      transition: .3s;
      border-radius: 5px;
      color: rgba(#222, .6);
      padding: 14px 20px;
      min-height: 82px;

      @include mq(719px) {
        padding: 10px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 9px;
        border: 2px solid #53BF31;
        border-radius: 0 0 3px 0;
        border-style: none solid solid none;
        transform: rotate(45deg);
        top: 8px;
        right: 8px;
        transition: .3s;
        opacity: 0;
      }
    }
    
    input[type=radio] {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;

      &:checked {
        + .checkout-radio__inner {
          background: #ffffff;

          &:after {
            opacity: 1;
          }

          .checkout-radio-type__title,
          .checkout-radio-point__name,
          .checkout-radio-type__price {
            color: #222;
          }
        }
      }
    }
    
    &-type {
      &__data {
        @include mq(719px) {
          flex: 1;
        }
      }

      &__price {
        @include mq(719px) {
          margin-left: 30px;
        }
      }

      &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title {
        @include mq(959px) {
          font-size: 14px;
        }
      }

      &__description {
        font-size: 14px;
        margin-top: 10px;
      }

      &__price {
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;

        @include mq(959px) {
          font-size: 16px;
          line-height: normal;
        }
      }
    }

    &-point {
      &__name {
        @include mq(959px) {
          font-size: 14px;
        }
      }
      &__address {
        margin-top: 10px;

        @include mq(959px) {
          font-size: 14px;
        }
      }
    }
  }

  &-inputs {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;

    @include mq(959px) {
      margin: -8px;
    }

    @include mq(719px) {
      margin: -5px;
    }

    &-item {
      width: calc(50% - 30px);
      margin: 15px;

      @include mq(959px) {
        width: calc(50% - 16px);
        margin: 8px;
      }

      @include mq(719px) {
        width: 100%;
        margin: 5px;
      }

      &--street {
        @include mq(719px) {
          margin-top: 30px;
        }
      }

      &--house {
        @include mq(719px) {
          margin-bottom: 30px;
        }
      }

      &--double {
        display: flex;

        .checkout__input-parent {
          width: calc(50% - 15px);

          @include mq(959px) {
            width: calc(50% - 8px);
          }
        }

        .checkout__input-parent + .checkout__input-parent {
          margin-left: 30px;

          @include mq(959px) {
            margin-left: 16px;
          }
        }
      }
    }
  }

  &-info {
    font-size: 14px;
    margin-top: 27px;

    @include mq(719px) {
      font-size: 12px;
    }
  }

  &-data {
    margin-left: 30px;
    border-radius: 5px;
    overflow: hidden;
    flex: 1;
    margin-top: 40px;

    @include mq(1200px, min) {
      margin-top: -45px;
    }

    @include mq(959px) {
      margin: 60px auto 0;
    }

    &__inner {
      background: #FBF6F1;
    }

    &__top {
      padding: 25px 20px 15px;
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
    }

    &__items {
      margin-top: 20px;
    }

    &-item {
      & + & {
        margin-top: 20px;
      }
      &__top {
        color: #939393;
        font-size: 12px;
        max-width: 65%;

        @include mq(1539px) {
          max-width: unset;
        }
      }

      &__bottom {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
      }
    }

    &__back {
      display: flex;
      align-items: center;
      margin-top: 25px;
      color: #222222;

      &:hover {
        color: $primaryHover;
      }

      svg {
        transform: rotate(90deg);
        margin-right: 12px;
      }
    }

    &-bottom {
      padding: 20px;
      border-top: 1px solid #F2F2F2;

      &-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq(1199px) {
          @include mq(960px, min) {
            flex-direction: column;
            align-items: baseline;
          }
        }

        & + & {
          margin-top: 20px;
        }

        &__title {
          color: rgba(#222, .6);
          max-width: 49%;

          @include mq(1199px) {
            @include mq(960px, min) {
              max-width: 80%;
            }
          }

          @include mq(719px) {
            max-width: 70%;
          }
        }

        &__value {
          @include mq(1199px) {
            @include mq(960px, min) {
              margin-top: 6px;
            }
          }

          @include mq(959px) {
            font-size: 14px;
            flex: 1;
            text-align: right;
          }
        }
      }

      &-total {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;

        @include mq(1199px) {
          @include mq(960px, min) {
            flex-direction: column;
            align-items: baseline;
          }
        }

        &__value {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    &-btn {
      color: #FFF;
      height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      cursor: pointer;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 23px 10px -15px rgba(0,0,0,0.08);
      width: 100%;
      border: none;
      transition: .3s;
      font-size: 16px;

      &:hover {
        background: $primaryHover;
      }
    }

    &__agreement {
      margin-top: 30px;
      font-size: 14px;
      color: #222;
      text-align: center;

      a {
        color: #222;

        &:not(:hover) {
          text-decoration: underline;
        }
      }
    }
  }
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  text-align: center;

  &--project-detail {
    margin-top: 0;
  }

  @include mq(1199px) {
    margin-top: 60px;
  }

  @include mq(719px) {
    margin-top: 40px;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
  }

  &-order {
    padding: 16px 74px 37px;
    border: 4px solid #E2F2FC;
    border-radius: 5px;
    margin-top: 30px;

    @include mq(719px) {
      padding-bottom: 16px;
    }

    &__title {
      font-size: 24px;
      line-height: 28px;

      @include mq(959px) {
        font-size: 16px;
        line-height: normal;
      }
    }

    &__number {
      font-size: 40px;
      font-weight: bold;
      margin-top: 5px;

      @include mq(959px) {
        font-size: 24px;
        line-height: normal;
      }
    }
  }

  &__subtitle {
    margin-top: 30px;

    @include mq(1199px) {
      width: 260px;
    }

    @include mq(959px) {
      margin-top: 23px;
      font-size: 14px;
      line-height: normal;
      width: 230px;
    }
  }

  &__email {
    font-size: 24px;
    margin-top: 6px;

    @include mq(959px) {
      font-size: 16px;
      line-height: normal;
    }
  }

  &-btn {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.051);
    margin-top: 35px;
  }

  &-more {
    margin-top: 90px;
    cursor: pointer;

    svg {
      margin-left: 16px;
      transform: rotate(-90deg);
    }
  }
}